import React from 'react'
import './Contact.css'
import ContactImg from '../../images/contact.jpg'
import { MdCall } from "react-icons/md";
import { BsFillChatDotsFill } from "react-icons/bs";
import {HiChatBubbleBottomCenter} from 'react-icons/hi2'


function Contact() {
  return (
    <section className='c-wapper'>
        <div className='innerWidth paddings flexCenter c-container'>
{/* left side */}
<div className='flexColStart c-left'>
<span className="orangeText">Our Contact Us</span>
          <span className="primaryText">Easy to contact us</span>
          <span className="secondaryText">
            We always ready to help by providijng the best services for you. We
            beleive a good blace to live can make your life better{" "}
          </span>
          <div className='flexColStart contactModes'>
{/* first row */}
<div className='flexStart row'>

<div className='flexColCenter mode'>

<div className='flexStart'>
{/* f-buton */}
    <div className='flexCenter icon'>
<MdCall size={25}/>
    </div>
    <div className='flexColStart detail'>
        <span>Call</span>
        <span>755001413</span>
       
    </div>
    
</div>
<div className='flexCenter button'>Call Now</div>


</div>
{/* second mode */}
<div className='flexColCenter mode'>

<div className='flexStart'>
{/* f-buton */}
    <div className='flexCenter icon'>
<BsFillChatDotsFill size={25}/>
    </div>
    <div className='flexColStart detail'>
        <span>Chat</span>
        <span>755001413</span>
       
    </div>
    
</div>
<div className='flexCenter button'>Chat Now</div>


</div>


</div>

{/* second row */}

<div className='flexStart row'>

<div className='flexColCenter mode'>

<div className='flexStart'>
{/* f-buton */}
    <div className='flexCenter icon'>
<BsFillChatDotsFill size={25}/>
    </div>
    <div className='flexColStart detail'>
        <span>VideoCall</span>
        <span>755001413</span>
       
    </div>
    
</div>
<div className='flexCenter button'>VideoCall Now</div>


</div>
{/* second mode */}
<div className='flexColCenter mode'>

<div className='flexStart'>
{/* f-buton */}
    <div className='flexCenter icon'>
<HiChatBubbleBottomCenter size={25}/>
    </div>
    <div className='flexColStart detail'>
        <span>Message</span>
        <span>755001413</span>
       
    </div>
    
</div>
<div className='flexCenter button'>Message Now</div>


</div>


</div>







          </div>
</div>
<div className='c-right'>
<div className='image-container'>
    <img src={ContactImg}/>
</div>
</div>
        </div>
    </section>
  )
}

export default Contact