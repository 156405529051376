import React from 'react'
import './Company.css'
import Porono from '../../images/prologis.png'
import Realty from '../../images/realty.png'
import Tower from '../../images/tower.png'
import Equinx from '../../images/equinix.png'
const Company = () => {
  return (
    <section className='c-wapper'>
<div className='paddings innerWidth flexCenter c-container'>
<img src={Porono} alt=''/>
<img src={Realty} alt=''/>
<img src={Tower} alt=''/>
<img src={Equinx} alt=''/>

</div>

    </section>
  )
}

export default Company