
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Contact from './componts/Contacts/Contact';
import Footer from './componts/Footers/Footer';
import Getstart from './componts/Getstart/Getstart';
import Hero from './componts/HERO/Hero';
import Header from './componts/Header/Header';
import Values from './componts/Values/Values';
import Company from './componts/companies/Company';
import Residences from './componts/residences/Residences';

function App() {
  return (
  
    <div className='App'>

     <div>
      <div className='white-gradient'/>
    
     <Header/>
    <Hero/>
     </div>
     
     <Company/>
     <Routes>
    
   <Route path='/residences' element={<Residences/>}/>
   <Route path='/ourvalues' element={<Values/>}/>
   <Route path='/contact' element={ <Contact/> }/> 
   <Route path='/getstart' element={ <Getstart/> }/> 


  

     </Routes>
    < Footer/>
    </div>
  
  );
}

export default App;
