import React from 'react'
import './Hero.css'
import Heroimg from '../../images/hero-image.png'
import { HiLocationMarker } from "react-icons/hi";
// import CountUp from 'react-countup/build/CountUp';
import { motion } from 'framer-motion';
function Hero() {
  return (
    <section className='flexCenter hero-wapper'>
<div className='hero-container flexCenter paddings innerWidth'>

{/* left side */}
<div className='flexColStart hero-left'>
    <div className='hero-title'>
      <div className='orange-cricle'/>  
      <motion.h1 
      initial={{y:'2rem', opacity:0}}
      animate={{y:0,opacity:1}}
      transition={{
        duration:2,
        type:'spring'
      }}



      > 
        <h1>Discover <br/> What You <br/> Deserve </h1>
        </motion.h1>
    </div>
<div className='flexColStart hero-des'>
    <span>Find a  variety of properties thats near you </span>
    <span>Forget all difficulties in finding a residence for you  </span>
</div>
<div className='flexCenter search-bar'>
<HiLocationMarker color='var(--blue)' size={25} /> 
<input type='text'/>
<button className='button'>Search</button>
</div>
<div className='flexCenter stats'>

{/* <div className=' stat'>

<span>2500 +</span>
<span>
  Premium Products
 </span>

<span>50+</span>
<span>Happy Customers</span>
<span> Award Winningd</span>
<span> 6+</span>


</div> */}




</div>
</div>

{/* right side */}
<motion.div
initial={{x:"7rem",opacity:0}}
animate={{x:0,opacity:1}}
transition={{
  duration:2,
  type:'spring'
}}
className=' flexCenter hero-right'> 
 <div className='image-container'>
    <img src={Heroimg}/>
 </div>
</motion.div>
</div>

    </section>
  )
}

export default Hero