import React from 'react'
import './Getstart.css'
const Getstart = () => {
  return (
    <div id="get-started" className="g-wrapper">
    <div className="paddings innerWidth g-container">
      <div className="flexColCenter inner-container">
        <span className="primaryText">Get started with Homyz</span>
        <span className="secondaryText">
          Subscribe and find super attractive price quotes from us.
          <br />
          Find your residence soon
        </span>
        <button className="button" href>
          <a href="#">Get Started</a>
        </button>
      </div>
    </div>
  </div>
  )
}

export default Getstart