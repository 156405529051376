import React from 'react'
import {Swiper,SwiperSlide,useSwiper} from 'swiper/react'
// import 'Swiper.css'
import "swiper/css";
import './Residences.css'
import data from '../../../src/Uitils/Slider.json'
// import Swiper from 'swiper'
import { sliderSettings } from '../../Uitils/Common';
import image1 from '../../Pictures/r1.png'
const Residences = () => {
  return (
    <section className='r-wapper'>
        <div className='paddings innerWidth r-container'>
            <div className='r-head flexColStart'>
                <span className='orangeText'>Best Choices</span>
                <span className='primaryText'>Popular Residencies</span>
            </div>
<Swiper {...sliderSettings}>  
{
    data.map((card,index)=>(
       <SwiperSlide key={index}>
<div className=' flexColStart r-card'>

    <img  src={image1} alt='Image Loading'/>
    <span className='secondaryText r-price'>
        <span style={{color:'orange'}}>Rs:</span><span>{card.price}</span>
    </span>
    <span className='primaryText'>{card.name}</span>
    <span className='secondaryTesx'>{card.detail}</span>
</div>
       </SwiperSlide>
    ))
}

</Swiper>
        </div>

    </section>
  )
}

export default Residences