import React, { useState } from "react";
import Logo from "../../images/logo.png";
import './Header.css'
import { BiMenuAltRight } from "react-icons/bi";
import { Link } from "react-router-dom";

function Header() {
  const[Openmenu,setOpenmenu]=useState(false)
  const getMenuopened=(Openmenu)=>{
if(document.documentElement.clientWidth <= 800){
  return{right: !Openmenu && '-100%'}
}
  }
  return (
    <section className="w-wapper">
      <div className=" flexCenter paddings h-container">
        <img src={Logo} alt="log img" width={100} />
      
       <div className="flexCenter h-menu"
        style={getMenuopened(Openmenu)}
        >
          <Link to="/residences">Residences</Link>
          <Link to="/ourvalues"> Our Values</Link>
          <Link to="/contact">Contact Us</Link>
          <Link to="/getstart">Get Started</Link>
          <button className="button">
            <Link to="">Contact</Link>
            </button>
        </div>


      
       
        <div className="menu-icon" onClick={()=>setOpenmenu((prv)=> !prv)}> 
       <BiMenuAltRight size={30} />
      </div>
      </div>
     
    </section>
  );
}

export default Header;
