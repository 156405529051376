import React,{useState} from 'react'
import './Value.css'
import valueImg from '../../images/value.png'
import data from '../../Uitils/accordion'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState,
  } from "react-accessible-accordion";
  import "react-accessible-accordion/dist/fancy-example.css";
  import {
    MdOutlineArrowDropDown,
    MdOutlineArrowDropDownCircle,
  } from "react-icons/md";
function Values() {
    
  return (
    <section className='v-wapper'>
        <div className='innerWidth paddings flexCenter v-container'>

{/* left */}
<div className='v-left'>
<div className='image-container'>
    <img src={valueImg}/>
</div>


</div>
{/* right */}
<div className='flexColStart v-right'>
<span className="orangeText">Our Value</span>

<span className="primaryText">Value We Give to You</span>

<span className="secondaryText">
  We always ready to help by providijng the best services for you.
  <br />
  We beleive a good blace to live can make your life better
</span>
<Accordion
className='accordion'
allowMultipleExpanded={false
}
preExpanded={[0]}
>
{
   
data.map( (item,index)=>{ 
    
    return(
        <AccordionItem className='accordionItem' key={index} uuid={index}>
            <AccordionItemHeading>
                <AccordionItemButton className='flexCenter accordionButton'>

<div className='flexCenter icon'>{item.icon}</div>
<span className='primaryText'>
    {item.heading}
</span>
<div className='flexCenter icon' >
    <MdOutlineArrowDropDown size={20}/>
</div>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <div className='secondaryText'>
                    {item.detail}
                </div>
            </AccordionItemPanel>
        </AccordionItem>
    )
})
}

</Accordion>

</div>






</div>

    </section>
  )
}

export default Values